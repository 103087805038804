import * as React from "react"

interface CurrentUser {
  active: boolean
  admin: boolean
  box: number
  displayName: string
  email: string
  emailVerified: boolean
  phoneNumber: string
  uid: string
}

interface CurrentUserContextType {
  currentUser: CurrentUser
  loading: boolean
}

export const CurrentUserContext = React.createContext<CurrentUserContextType | null>(
  null
)
