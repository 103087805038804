import * as React from "react"
import { auth, firestore } from "../core/firebase"
import { CurrentUserContext } from "./CurrentUserContext"

export const CurrentUserProvider: React.FC = props => {
  const { children } = props
  const [currentUser, setCurrentUser] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    let unsubscribe

    auth.onAuthStateChanged(async user => {
      if (user) {
        const token = await auth.currentUser.getIdToken()
        const context = { headers: { authorization: `Bearer ${token}` } }

        unsubscribe = firestore
          .collection("users")
          .doc(user.uid)
          .onSnapshot(doc => {
            setCurrentUser(doc.data())
            setLoading(false)
          })
      } else {
        setLoading(false)
        setCurrentUser(null)
      }
    })

    return () => unsubscribe()
  }, [])

  if (loading) return null

  return (
    <CurrentUserContext.Provider value={{ currentUser, loading }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserProvider
