import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const config = {
  apiKey: "AIzaSyBsBOuOxf9IGo3X4pYZFQj2rs5zseQbS7o",
  authDomain: "sublist-vgraphql.firebaseapp.com",
  databaseURL: "https://sublist-vgraphql.firebaseio.com",
  projectId: "sublist-vgraphql",
  storageBucket: "sublist-vgraphql.appspot.com",
  messagingSenderId: "507194650929",
  appId: "1:507194650929:web:738989ffc52c7b85",
}

let _app, _auth, _firestore

if (typeof window !== "undefined") {
  _app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app()
  _auth = _app.auth()
  _firestore = _app.firestore()
}

export const auth = _auth
export const firestore = _firestore
